<template>
    <div class="pricing">
        <div class="wrapper">
            <div class="page-header page-header-small clear-filter" filter-color="primary">
                <div class="page-header-image"></div>
                <div class="content-center">
                    <div class="row">
                        <div class="col-md-10 ml-auto mr-auto">
                            <h1 class="title">No subscription costs or signup fees</h1>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="section section-basic-components">
                <div class="container">
                    <div class="row">
                        <div class="col-12 mr-auto text-center">
                            <h2 class="title">Why use DrRemo?</h2>
                            <h4 class="description">
                                DrRemo provides a free platform that connect patients with practitioners. 
                                Our easy to use booking system facilitates a seamless payment process, securing bookings for patients and payment for practitioners. 
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="section section-basic-components" data-background-color="black">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="info info-hover d-flex h-100 align-items-center">
                                <div class="icon icon-success icon-circle">
                                    <i class="fa fa-hospital-user"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-7 ml-auto mr-auto">
                            <h2 class="title">As a patient, what does it cost?</h2>
                            <h5 class="description">
                                Using the DrRemo platform is free, with bookings being priced on a Pay As You Go model. 
                                With each booking you make as a patient, there will be a facilitation fee added to the final payment due.  
                                The facilitation fee is proportional to the practitioner's consultation fee and will as such vary depending on which practitioner and what type of consultation you book for.
                            </h5>
                            
                            <h2 class="title">How does payment work?</h2>
                            <h5 class="description">
                                As part of the booking process, once you have selected your desired practitioner, date, time and booking type, you will be redirected to our payment processor for card payments. 
                                Immediate payment is required upon making a booking in order to secure your consultation. 
                                Unfortunately at this stage DrRemo does not integrate with medical aid schemes, but we are working hard to get there!
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="section section-basic-components">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-md-7 ml-auto mr-auto">
                            <h2 class="title">As a practitioner, what does it cost?</h2>
                            <h5 class="description">
                                Using the DrRemo platform for your practice is free. 
                                Bookings are priced on a Pay As You Go model, with a facilitation fee added to the final bill for the patient.
                            </h5>
                            
                            <h2 class="title">How does payment work?</h2>
                            <h5 class="description">
                                Upfront payment from patients is required at the point of scheduling the booking on DrRemo. 
                                The funds will in turn be paid to your practice on a weekly basis.
                            </h5>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="info info-hover d-flex h-100 align-items-center">
                                <div class="icon icon-success icon-circle">
                                    <i class="fa fa-user-md"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="separator-line separator-primary"></div>

            <div class="section section-basic-components">
                <div class="d-flex flex-row justify-content-center">
                    <div class="flex-column align-self-center">
                        <card class="contactus-card text-center">
                            <h4 class="card-title"><b>Want to know more?</b></h4>
                            <p class="card-subtitle">If you would like to get more information, please do not hesitate to contact us <i class="far fa-smile"></i></p>
                            <n-button type="primary" @click="goToContactUs">CONTACT US</n-button>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Card } from "@/components";
    import { Button } from "@/components";

    export default {
        name: "pricing",
        components: {
            Card,
            [Button.name]: Button
        },
        methods: {
            goToContactUs() {                
                var self = this;
                self.$router.push({name:"contactus"}); 
            }
        },
        metaInfo() {
            return {
            title: "DrRemo | Pricing",
                meta: [
                    {
                        name: "description",
                        content: "Pricing and payment information."
                    },
                    {
                        name: "robots",
                        content: "index, follow"
                    }
                ],
                link: [
                    {
                        rel: "canonical",
                        href: "https://dr-remo.co.za/pricing"
                    }
                ]
            }
        }
    };
</script>
